'use client'

import { GeolocationContext } from '@components/hooks/GeolocationProvider'
import { Badge } from '@components/primitives/Badge'
import { IconPrefix } from '@components/primitives/Icon/IconPrefix'
import { Tooltip } from '@components/primitives/Tooltip'
import { classMerge } from '@components/utilities/classMerge'
import { faStopwatch } from '@fortawesome/pro-regular-svg-icons/faStopwatch'
import { parsePhaseFromPhaseList } from '@modules/trials/helpers/getPhaseFromPhaseList'
import getSortedTagListFromTrial from '@modules/trials/helpers/getSortedTagListFromTrial'
import {
  getNearbyTrialDistance,
  prettyDistanceString,
} from '@modules/trials/helpers/getTrialAttributes'
import { getTrialStatusWithDescription } from '@modules/trials/helpers/getTrialStatus'
import type { TrialForSummaryCard } from '@modules/trials/types/TrialForSummaryCard'
import type { ClinicalTrialStatus } from '@prisma/client'
import { useContext, useEffect, useState } from 'react'

export type StatusPillsProps = {
  className?: string
  distance?: string
  phase: string
  recruiting: ClinicalTrialStatus
}

export default function StatusPills({ trial }: { trial: TrialForSummaryCard }) {
  const userLocation = useContext(GeolocationContext)
  const [distance, setDistance] = useState<String>()

  useEffect(() => {
    setDistance(
      userLocation
        ? prettyDistanceString(getNearbyTrialDistance(trial, userLocation))
        : undefined,
    )
  }, [])

  const phase = parsePhaseFromPhaseList(trial.phaseList)
  const hasPhase = phase.label !== 'N/A'
  const isResponsive = trial.isResponsive
  const isRecruiting = trial.overallStatus === 'Recruiting'
  // We consider all trials that are not actively recruiting to be 'waitlist available' trials
  const recruiting = Tooltip({
    ...getTrialStatusWithDescription(
      isRecruiting ? 'Recruiting' : 'NotYetRecruiting',
    ),
    labelClassName: 'text-green800',
    labelStyleName: 'p-tiny-strong',
    underline: false,
  })

  // Include at most one extra pill
  const extraPills = getSortedTagListFromTrial(trial).slice(0, 1)

  const extraBadgeClassNames = 'mr-1 lg:mr-2'

  return (
    <>
      {hasPhase && (
        <Badge
          className={extraBadgeClassNames}
          colorGroup='hope'
          label={
            <Tooltip
              description={phase.description}
              label={phase.label}
              labelClassName={'text-hope800'}
              labelStyleName={'p-tiny-strong'}
              underline={false}
            />
          }
          variant='accent'
        />
      )}
      {recruiting && (
        <Badge
          className={extraBadgeClassNames}
          colorGroup='green'
          label={recruiting}
          variant='accent'
        />
      )}

      {isResponsive && (
        <Badge
          className={extraBadgeClassNames}
          colorGroup='blue'
          label={<IconPrefix icon={faStopwatch}>Quick Reply</IconPrefix>}
          variant='accent'
        />
      )}

      {/* Hide on mobile to reduce total pill count */}
      {distance && (
        <Badge
          className={classMerge(extraBadgeClassNames, {
            'hidden lg:block': hasPhase && recruiting,
          })}
          colorGroup='blue'
          label={distance}
          variant='accent'
        />
      )}

      {/* Extra pills from filter values that we've tagged  */}
      {!distance &&
        extraPills.length > 0 &&
        extraPills.map((tag) => (
          <Badge
            className={classMerge(extraBadgeClassNames, {
              'hidden lg:block': hasPhase && recruiting,
            })}
            colorGroup='neutral'
            key={tag}
            label={tag}
            variant='accent'
          />
        ))}
    </>
  )
}
